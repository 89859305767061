import { ChangeDetectionStrategy, Component } from '@angular/core';
import type {
  FormInputComponent,
  InputComponentConfig,
} from '@vendure/admin-ui/core';
import { type UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'loyalty-stamps-list',
  templateUrl: './loyalty-stamps-list.component.html',
  styleUrls: ['./loyalty-stamps-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoyaltyStampsListComponent implements FormInputComponent {
  static readonly id = 'loyalty-stamps-list';
  readonly isListInput = true;

  readonly!: boolean;
  formControl!: UntypedFormControl;
  config!: InputComponentConfig;
}
