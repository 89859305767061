import { NgModule } from '@angular/core';
import {
  SharedModule,
  registerFormInputComponent,
} from '@vendure/admin-ui/core';
import { LoyaltyStampsListComponent } from './components/loyalty-stamps-list/loyalty-stamps-list.component';

@NgModule({
  imports: [SharedModule],
  providers: [
    registerFormInputComponent(
      'loyalty-stamps-list',
      LoyaltyStampsListComponent,
    ),
  ],
  declarations: [LoyaltyStampsListComponent],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class SharedUIModuleLoyalty {}
